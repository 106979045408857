import React from 'react';

const ContentImage = ({ image, title, reverse, children }) => {
  const flexRow = reverse ? `flex-row-reverse` : `flex-row`;
  return (
    <>
      <section className="py-12 block">
        <div className="w-11/12 max-w-screen-2xl relative py-6 mx-auto">
          <div
            className={`flex ${flexRow} flex-wrap sm:flex-nowrap gap-16 justify-center m-auto`}
          >
            <div className="grow text-left grid gap-6">
              <div className="grid gap-2 max-w-4xl text-4xl">
                <div className="h-12"></div>
                <h2 className="text-4xl m-0 text-gray-900 font-bold p-0">
                  {title}
                </h2>
              </div>
              <div className="text-base">{children}</div>
            </div>
            <div className="flex flex-none w-11/12 sm:w-[320px] md:w-[480px] m-auto">
              <img
                className="rounded-md object-contain w-full max-h-[360px] m-auto"
                src={image}
                alt={title}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="py-12 block"></section>
    </>
  );
};

export default ContentImage;
