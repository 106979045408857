import * as React from 'react';
import Layout from './layout';
import PageHeader from './DarkHeader';

const Products = ({ pageTitle, description, children }) => {
  return (
    <Layout pageTitle={pageTitle} pageDescription={description}>
      <PageHeader title={pageTitle} description={description}>
        <div className="bg-white">
          <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
            {children}
          </div>
        </div>
      </PageHeader>
    </Layout>
  );
};

export default Products;
